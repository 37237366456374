var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Inscriptions")]),(_vm.$vuetify.breakpoint.smAndUp)?[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v("Rechercher")])]},proxy:true}],null,false,76880263),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]:_vm._e()],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.registrations,"loading":_vm.registrationsAreLoading,"search":_vm.search,"custom-filter":_vm.customFilter},on:{"click:row":_vm.openDecisionDialog},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.xsOnly)?{key:"top",fn:function(){return [_c('div',{staticClass:"px-4 d-flex"},[_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v("Rechercher")])]},proxy:true}],null,false,76880263),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}:null,{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v("# "+_vm._s(item.id))])]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-2"},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":_vm.getGenderColor(item.gender)}},[_vm._v(" mdi-account-circle ")]),_c('div',[_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name.toUpperCase())+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.email)+" ")])])],1)]}},{key:"item.birth_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.birth_date))+" ")]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","href":(_vm.baseUrl + "admin/misc-get-pdf.php?type=dahu&id=" + (item.id) + "&access=" + _vm.accessToken),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-file")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getRegistrationStatus(item.analysis, item.validated).color,"x-small":""}},[_vm._v(" mdi-circle ")]),_c('span',{staticClass:"font-weight-medium",class:((_vm.getRegistrationStatus(item.analysis, item.validated).color) + "--text")},[_vm._v(" "+_vm._s(_vm.getRegistrationStatus(item.analysis, item.validated).text)+" ")])],1)]}}],null,true)}),(_vm.registrationToAnalyse !== undefined)?_c('dialog-analyse',{attrs:{"registration":_vm.registrationToAnalyse,"justifications":_vm.justifications,"cursusDept":_vm.cursusDept},on:{"analysis-saved":_vm.updateRegAnalysis,"analyse-sent":_vm.markAnalysisAsSent},model:{value:(_vm.analyseDialogIsActive),callback:function ($$v) {_vm.analyseDialogIsActive=$$v},expression:"analyseDialogIsActive"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }