<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title>Inscriptions</v-toolbar-title>

      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        >
          <template v-slot:label>
            <span class="font-weight-bold">Rechercher</span>
          </template>
        </v-text-field>
      </template>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="registrations"
      :loading="registrationsAreLoading"
      :search="search"
      :custom-filter="customFilter"
      @click:row="openDecisionDialog"
    >
      <template v-slot:top v-if="$vuetify.breakpoint.xsOnly">
        <div class="px-4 d-flex">
          <v-spacer class="d-none d-sm-flex"></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            hide-details
          >
            <template v-slot:label>
              <span class="font-weight-bold">Rechercher</span>
            </template>
          </v-text-field>
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <span class="font-weight-medium"># {{ item.id }}</span>
      </template>

      <template v-slot:item.last_name="{ item }">
        <div class="d-flex align-center py-2">
          <v-icon
            class="mr-2"
            large
            :color="getGenderColor(item.gender)"
          >
            mdi-account-circle
          </v-icon>

          <div>
            <div class="font-weight-medium">
              {{ item.first_name }} {{ item.last_name.toUpperCase() }}
            </div>

            <div class="text-caption">
              {{ item.email }}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.birth_date="{ item }">
        {{ item.birth_date | formatDate }}
      </template>

      <template v-slot:item.file="{ item }">
        <v-btn
          icon
          :href="`${baseUrl}admin/misc-get-pdf.php?type=dahu&id=${item.id}&access=${accessToken}`"
          target="_blank"
          @click.stop
        >
          <v-icon>mdi-file</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="d-flex align-center">
          <v-icon
            :color="getRegistrationStatus(item.analysis, item.validated).color"
            x-small
            class="mr-2"
          >
            mdi-circle
          </v-icon>

          <span
            class="font-weight-medium"
            :class="`${getRegistrationStatus(item.analysis, item.validated).color}--text`"
          >
            {{ getRegistrationStatus(item.analysis, item.validated).text }}
          </span>
        </div>
      </template>
    </v-data-table>

    <dialog-analyse
      v-if="registrationToAnalyse !== undefined"
      v-model="analyseDialogIsActive"
      :registration="registrationToAnalyse"
      :justifications="justifications"
      :cursusDept="cursusDept"
      @analysis-saved="updateRegAnalysis"
      @analyse-sent="markAnalysisAsSent"
    ></dialog-analyse>
  </v-card>
</template>

<script>
import { getGenderColor } from '@/mixins/methodsMixin';
import DialogAnalyse from '../components/notification/DialogAnalyse.vue';

export default {
  // name: 'AdminDahuRegistrations',

  props: {
    cursusId: String,
    cursusDept: String,
  },

  mixins: [getGenderColor],

  components: {
    DialogAnalyse,
  },

  data: () => ({
    items: [
      {
        text: 'Instances',
        disabled: false,
        to: '/',
      },
      {
        text: 'Cursus',
        disabled: false,
        to: '/dahu',
      },
      {
        text: 'Inscriptions',
        disabled: true,
        to: '/registrations',
      },
    ],
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'ETUDIANT', value: 'last_name' },
      { text: 'DATE DE NAISSANCE', value: 'birth_date' },
      {
        text: 'NATIONALITE',
        value: 'nationality',
        sortable: false,
        filterable: false,
      },
      {
        text: 'PAYS',
        value: 'country',
        sortable: false,
        filterable: false,
      },
      {
        text: 'DOSSIER',
        value: 'file',
        sortable: false,
        filterable: false,
      },
      {
        text: 'STATUT',
        value: 'status',
        sortable: false,
        filterable: false,
      },
    ],
    registrations: [],
    registrationsAreLoading: false,
    search: '',
    justifications: [],
    editedIndex: -1,
    analyseDialogIsActive: false,
  }),

  created() {
    this.getRegistrations();
    this.getAllJustifications();
  },

  computed: {
    accessToken() {
      return this.$http.defaults.headers.common.Authorization.replace('Bearer ', '');
    },

    baseUrl() {
      return process.env.VUE_APP_AXIOS_BASE_URL;
    },

    registrationToAnalyse() {
      return this.registrations[this.editedIndex];
    },
  },

  watch: {
    analyseDialogIsActive(val) {
      if (!val) this.closeDecisionDialog();
    },
  },

  methods: {
    async getRegistrations() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.registrationsAreLoading = true;
        const response = await this.$http.get(`admin/registration-get.php?cursus_id=${this.cursusId}&type=DAHU`);
        this.registrations = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.registrationsAreLoading = false;
      }
    },

    async getAllJustifications() {
      await this.$store.dispatch('getAccessToken');
      try {
        const response = await this.$http.get('admin/misc-get-justifications.php?type=DAHU');
        this.justifications = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    openDecisionDialog(row) {
      this.editedIndex = this.registrations.indexOf(row);
      this.analyseDialogIsActive = true;
    },

    closeDecisionDialog() {
      this.analyseDialogIsActive = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    getRegistrationStatus(analysis, validation) {
      if (analysis === 'acceptance' && validation) return { text: 'Acceptée', color: 'success' };
      if (analysis === 'refusal' && validation) return { text: 'Refusée', color: 'error' };
      if (analysis && !validation) return { text: 'Analysée', color: 'info' };
      return { text: 'En attente', color: 'warning' };
    },

    updateRegAnalysis(updatedReg) {
      Object.assign(this.registrations[this.editedIndex], updatedReg);
    },

    markAnalysisAsSent() {
      this.registrations[this.editedIndex].validated = true;
      this.closeDecisionDialog();
    },

    customFilter(value, search, item) {
      const lowerCaseSearch = search.toLowerCase();
      if (value === item.last_name
      && (value.indexOf(lowerCaseSearch) !== -1
      || item.first_name.indexOf(lowerCaseSearch) !== -1
      || item.email.indexOf(lowerCaseSearch) !== -1)) {
        return true;
      }
      if (value === item.birth_date) {
        return this.$options.filters.formatDate(value).indexOf(lowerCaseSearch) !== -1;
      }
      return value.toString().indexOf(lowerCaseSearch) !== -1;
    },
  },
};
</script>
