<template>
  <v-dialog
    v-model="isVisible"
    max-width="800"
    :persistent="analysisIsSaving"
  >
    <v-card>
      <v-card-title class="primary white--text">
        Analyse de la demande

        <v-spacer></v-spacer>

        <v-btn
          icon
          dark
          @click="isVisible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="analysisForm"
          :disabled="analysisIsSaving
          || registration.validated || !isAllowedToAnalyse"
        >
          <v-radio-group
            label="Demande de l'étudiant : "
            v-model="decision"
            @change="resetJustifications"
            :rules="[(v) => !!v || '']"
            row
          >
            <v-radio
              label="Accepter"
              value="acceptance"
              color="success"
            ></v-radio>

            <v-radio
              label="Refuser"
              value="refusal"
              color="error"
            ></v-radio>
          </v-radio-group>

          <v-select
            label="Motivation"
            v-model="justifId"
            :items="availableJustifications"
            item-text="justification"
            item-value="id"
            outlined
            @change="hasSavedJusti = false; customJustif = null;"
            required
            :rules="[(v) => !!v || 'Ce champ est obligatoire']"
            :disabled="!decision"
            dense
          ></v-select>

          <v-textarea
            v-if="[45, 53, 61, 71, 79, 85].includes(justifId)"
            v-model="customJustif"
            label="Motivation libre"
            outlined
            no-resize
            maxlength="2048"
            counter="2048"
            rows="3"
            required
            @input="hasSavedJusti = false"
            :rules="[(v) => !!v || 'Ce champ est obligatoire']"
            dense
          ></v-textarea>

          <template v-if="!registration.validated">
            <v-btn
              v-if="isAllowedToAnalyse"
              :loading="analysisIsSaving"
              color="primary"
              @click="saveAnalysis"
              class="mr-4"
            >
              Enregistrer
            </v-btn>

            <!-- eslint-disable max-len -->
            <v-btn
              :disabled="analysisIsSaving || !hasSavedJusti"
              class="mr-4"
              color="secondary"
              :href="`${baseUrl}admin/misc-preview-analysis.php?type=dahu&registration_id=${registration.id}&access=${accessToken}`"
              target="_blank"
            >
              Afficher
            </v-btn>
            <!-- eslint-enable max-len -->

            <dialog-confirm-analyse-sending
              :isDisplayed="isAllowedToAnalyseAsDir"
              :isDisabled="analysisIsSaving || !hasSavedJusti"
              :registrationId="registration.id"
              :type="'dahu'"
              @analyse-sent="$emit('analyse-sent')"
            ></dialog-confirm-analyse-sending>
          </template>
        </v-form>

        <v-alert
          v-if="registration.validated"
          icon="mdi-check"
          text
          type="info"
          prominent
        >
          <v-row class="align-center">
            <v-col class="grow">
              L'analyse de la demande de l'étudiant lui a été envoyée par email
            </v-col>

            <v-col class="shrink">
              <!-- eslint-disable max-len -->
              <v-btn
                color="primary"
                :href="`${baseUrl}admin/misc-preview-analysis.php?type=dahu&registration_id=${registration.id}&access=${accessToken}`"
                target="_blank"
              >
                Afficher
              </v-btn>
              <!-- eslint-enable max-len -->
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogConfirmAnalyseSending from '@/components/notification/DialogConfirmAnalyseSending.vue';

export default {
  name: 'DialogAnalyse',

  components: {
    DialogConfirmAnalyseSending,
  },

  props: {
    registration: Object,
    value: Boolean,
    justifications: Array,
    cursusDept: String,
  },

  data: () => ({
    analysisIsSaving: false,
    hasSavedJusti: false,
    decision: null,
    justifId: null,
    customJustif: '',
  }),

  computed: {
    isVisible: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      },
    },
    availableJustifications() {
      return this.justifications
        .filter((justification) => justification.type === this.decision);
    },

    isAllowedToAnalyseAsGes() {
      const isGes = this.$store.state.user.roles.includes(`APP.INS.DAHU.${this.cursusDept}.GES`);
      const isAdmin = this.$store.state.user.roles.includes('APP.INS.ADMIN');
      return isGes || isAdmin;
    },

    isAllowedToAnalyseAsDir() {
      const isDir = this.$store.state.user.roles.includes(`APP.INS.DAHU.${this.cursusDept}.DIR`);
      const isAdmin = this.$store.state.user.roles.includes('APP.INS.ADMIN');
      return isDir || isAdmin;
    },

    isAllowedToAnalyse() {
      return this.isAllowedToAnalyseAsGes || this.isAllowedToAnalyseAsDir;
    },

    accessToken() {
      return this.$http.defaults.headers.common.Authorization.replace('Bearer ', '');
    },

    baseUrl() {
      return process.env.VUE_APP_AXIOS_BASE_URL;
    },
  },

  async created() {
    this.decision = this.registration.analysis;
    this.justifId = this.registration.justification;
    this.customJustif = this.registration.custom_justification;
    if (this.justifId) this.hasSavedJusti = true;
  },

  methods: {
    async saveAnalysis() {
      if (!this.$refs.analysisForm.validate()) return;
      await this.$store.dispatch('getAccessToken');
      try {
        this.analysisIsSaving = true;
        const response = await this.$http.post('admin/misc-save-analysis.php', {
          registration_id: this.registration.id,
          justification: this.justifId,
          custom_justification: this.customJustif,
          type: 'dahu',
        });
        this.$emit('analysis-saved', {
          analysis: this.decision,
          justification: this.justifId,
          custom_justification: this.customJustif,
        });
        this.hasSavedJusti = true;
        this.$store.commit('SET_NOTIFICATION', {
          isVisible: true,
          text: response.data,
          color: 'success',
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.analysisIsSaving = false;
      }
    },

    resetJustifications() {
      this.hasSavedJusti = false;
      this.justifId = null;
      this.customJustif = null;
    },
  },
};
</script>
